















import { Vue, Component, Prop } from "vue-property-decorator";
import { Select, Option } from "element-ui";
@Component({
    components: {
        "el-select": Select,
        "el-option": Option
    }
})
export default class FormItemSelect extends Vue {
    @Prop({
        type: [String, Number, Object],
        required: false,
        default: () => ({})
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //

    options: object = [];

    val: any = ""; //必须为number

    // select钩子，选中值发生变化时触发
    change(data: any) {
        this.$emit("onchange", data);
    }

    getOption() {
        const getopt = this.$api.apiList[this.formItemOpt.apikey];
        const opt = getopt();
        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        })
            .then((res: any) => {
                this.options = res.data[this.formItemOpt.apiResKey];
            })
            .catch((res: any) => {});
    }
    mounted() {
        if (this.value === 0 || this.value === "0") {
            //    this.val
        } else {
            if (typeof this.value != "number") {
                this.val = Number(this.value);
            } else {
                this.val = this.value;
            }
        }
        // this.val = this.value;
        this.getOption();
    }
}
